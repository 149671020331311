import { MutableRefObject, useEffect } from 'react';

export const useOutsideClick = (
  ref: MutableRefObject<HTMLElement | null>,
  callBack: () => void,
  ignoreRef?: MutableRefObject<HTMLElement | null>,
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ignoreRef?.current) {
        if (
          ref?.current &&
          !ref?.current.contains(event.target) &&
          ignoreRef?.current &&
          !ignoreRef?.current?.contains(event.target)
        ) {
          callBack();
        }
      } else {
        if (ref?.current && !ref?.current.contains(event.target)) {
          callBack();
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return null;
};
