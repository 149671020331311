import * as React from 'react';

import { Box, Container, Text } from './Design';

interface PageTextBannerProps {
  backgroundColor?: React.ComponentProps<typeof Box>['backgroundColor'];
  color?: React.ComponentProps<typeof Box>['color'];
  children?: React.ReactNode;
  zIndex?: React.ComponentProps<typeof Box>['zIndex'];
}

const PageTextBanner = ({
  backgroundColor = 'gray',
  color = 'primary',
  zIndex,
  children,
}: PageTextBannerProps) => {
  return (
    <Box minHeight="44px" backgroundColor={backgroundColor} zIndex={zIndex} position="relative">
      <Container
        minHeight="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingY="12px"
      >
        {typeof children === 'string' ? (
          <Text color={color} width="fitContent" textAlign="center">
            {children}
          </Text>
        ) : (
          children
        )}
      </Container>
    </Box>
  );
};

export default PageTextBanner;
