import { useRouter } from 'next/router';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react';
import { Controller, useForm } from 'react-hook-form';

import { UnknownDict } from '../../global';
import useAnalytics from '../../hooks/useAnalytics';
import { searchSuggest } from '../../pages/api/shopify/search-suggest';
import { useProductStore } from '../../store/rootStoreProvider';
import {
  searchFormButton,
  searchFormCatSelect,
  searchFormContainer,
  searchFormInput,
} from '../../styles/index.css';
import { cleanSearchQuery } from '../../utils';
import {
  KnownProductTypes,
  categoryOptions,
  defaultCategoryOptionsValues,
} from '../../utils/const';
import { Box, Button, Flex, Input, Select, Stack } from '../Design';
import { SearchIcon } from '../Design/Icons';
import { FlexProps } from '../Design/Primitives/components/Flex';
import { colors, vars } from '../Design/vars.css';

export interface SearchFormProps extends FlexProps<'div'> {
  isMobile?: boolean;
}

const SearchForm = ({ isMobile, ...props }: SearchFormProps) => {
  const { pathname, push: routerPush, replace: routerReplace } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const {
    searchQuery,
    currentSearchFormValue,
    setFilterValues,
    setSortBy,
    sortBy,
    setCategory,
    category,
    setCurrentSearchFormValue,
    productsPerPage,
    setCurrentPage,
  } = useProductStore();

  const { control, handleSubmit, getValues, setValue, reset } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      search: cleanSearchQuery(searchQuery) ?? '',
      category: category ?? defaultCategoryOptionsValues,
    },
  });
  const [searchSuggestions, setSearchSuggestions] = useState<
    {
      url: string;
      text: string;
      styled_text: string;
    }[]
  >([]);
  const [suggestionIndex, setSuggestionIndex] = useState<number | undefined>(undefined);
  const preservedInputValue = useRef<string | null>();
  const { trackSearch } = useAnalytics();

  const onKeyDown = (e: any) => {
    if (searchSuggestions?.length === 0) {
      return;
    }
    if (e.key === 'Enter' && typeof suggestionIndex === 'number' && searchSuggestions?.length > 0) {
      setValue('search', searchSuggestions?.[suggestionIndex]?.text);
      setSearchSuggestions([]);
      setSuggestionIndex(undefined);
      return;
    } else if (e.key === 'Enter') {
      return;
    }

    if (e.keyCode === 38 && typeof suggestionIndex !== 'undefined') {
      // Up Arrow
      e.preventDefault();

      setSuggestionIndex(prevState => {
        const current = !prevState ? undefined : prevState - 1;
        return current;
      });
    } else if (e.keyCode === 40 && (suggestionIndex ?? 0) <= searchSuggestions.length - 1) {
      // Down Arrow
      e.preventDefault();
      if (typeof suggestionIndex === 'undefined') {
        preservedInputValue.current = getValues('search');
      }

      setSuggestionIndex(prevState => {
        const current = typeof prevState === 'undefined' ? 0 : prevState + 1;
        return current;
      });
    }
  };

  // Used to set category on pageChange = based on last product search
  useEffect(() => {
    let mounted = true;
    if (!mounted) return;

    if (category && category !== getValues('category')) {
      setValue('category', category as KnownProductTypes);
    }

    return () => {
      mounted = false;
    };
  }, [category, getValues, setValue]);

  useEffect(() => {
    setValue('search', currentSearchFormValue ?? '');
  }, [currentSearchFormValue]);

  const conditionalStyles = isMobile
    ? { width: '24px', height: '24px' }
    : { width: '20px', height: '20px' };

  const onSubmit = async (data: UnknownDict) => {
    setIsLoading(true);
    setFilterValues(undefined);
    if (sortBy !== 'New Arrivals') setSortBy('New Arrivals');

    setCategory(data.category ?? defaultCategoryOptionsValues);
    await setCurrentSearchFormValue(data?.search);

    setSuggestionIndex(undefined);
    setSearchSuggestions([]);
    setCurrentPage(1);
    const routerData = {
      pathname: '/shop',
      query: { searchQuery: data?.search?.trim(), categoryType: data?.category, productsPerPage },
    };

    trackSearch(`SearchForm: ${data?.search}`, category ?? KnownProductTypes.all);

    reset({ search: data?.search ?? '', category: category ?? KnownProductTypes.all });

    if (pathname.includes('/shop')) {
      routerReplace(routerData);
    } else {
      routerPush(routerData);
    }
    setIsLoading(false);
  };

  return (
    <Flex
      element="form"
      className={searchFormContainer}
      onSubmit={(e: BaseSyntheticEvent<object, any, any> | undefined) => handleSubmit(onSubmit)(e)}
      {...props}
    >
      <Controller
        name="category"
        control={control}
        render={({ field }) => {
          return (
            <Select
              aria-label={field.name}
              variant="filled"
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              maxHeight={isMobile ? '40px' : '32px'}
              fontSize="md"
              className={searchFormCatSelect}
              containerProps={{ className: searchFormCatSelect }}
            >
              {categoryOptions.map(({ name, value }) => (
                <option key={`${name}-${value}`} value={value}>
                  {name}
                </option>
              ))}
            </Select>
          );
        }}
      />

      <Flex direction="row" flex="1" width="full">
        <Controller
          name="search"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Box position="relative" flex="1" width="full">
                <Input
                  type="search"
                  aria-label="Search for products"
                  name={field.name}
                  value={field.value}
                  aria-haspopup="listbox"
                  aria-controls={`search_dropdown-${isMobile ? 'mobile' : 'desktop'}`}
                  aria-activedescendant={
                    suggestionIndex ? `search_element_${suggestionIndex}` : undefined
                  }
                  onKeyDown={onKeyDown}
                  onChange={async (e: any) => {
                    const value = e.target.value;
                    if (value?.length > 2) {
                      searchSuggest(value).then(searchSuggestion => {
                        setSearchSuggestions(searchSuggestion);
                      });
                    } else if (value?.length <= 2) {
                      setSuggestionIndex(undefined);
                      setSearchSuggestions([]);
                    }
                    field.onChange(e);
                  }}
                  className={searchFormInput}
                  placeholder={fieldState.error?.message ?? 'Search by term or category...'}
                  width="full"
                  flex="1"
                  maxHeight={isMobile ? '40px' : '32px'}
                />

                <Stack
                  element="ul"
                  id={`search_dropdown-${isMobile ? 'mobile' : 'desktop'}`}
                  role="listbox"
                  aria-multiselectable={false}
                  tabIndex={-1}
                  position="absolute"
                  backgroundColor="white"
                  boxShadow="elevated"
                  zIndex="header"
                  left="0px"
                  width="full"
                  borderBottomRightRadius="base"
                  borderBottomLeftRadius="base"
                  border="1px"
                  borderColor="border"
                  display={searchSuggestions?.length > 0 ? 'flex' : 'none'}
                  style={{ transition: 'all 0.2s ease-in-out' }}
                >
                  {searchSuggestions?.map((suggestion, idx) => {
                    return (
                      <Button
                        element="li"
                        key={suggestion?.text}
                        variant="unstyled"
                        justifyContent="flex-start"
                        id={`search_element_${suggestionIndex}`}
                        aria-selected={
                          suggestionIndex
                            ? suggestion?.text === searchSuggestions?.[suggestionIndex]?.text
                            : false
                        }
                        role="option"
                        onClick={() => {
                          setValue('search', suggestion?.text);
                        }}
                        style={{
                          background: suggestionIndex === idx ? colors.primary : undefined,
                          color: suggestionIndex === idx ? colors.white : undefined,
                          borderRadius: '0px',
                        }}
                      >
                        {suggestion?.text}
                      </Button>
                    );
                  })}
                </Stack>
              </Box>
            );
          }}
        />
        <Button
          aria-label="search"
          type="submit"
          fontSize="md"
          isLoading={isLoading}
          loaderSize="sm"
          maxHeight={isMobile ? '40px' : '32px'}
          className={searchFormButton}
          style={{ borderTopLeftRadius: '0', borderBottomLeftRadius: '0' }}
        >
          <SearchIcon style={{ ...conditionalStyles, color: vars.color.white }} />
        </Button>
      </Flex>
    </Flex>
  );
};

export default observer(SearchForm);
