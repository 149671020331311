import { observer } from 'mobx-react';

import { useCartStore } from '../../../store/rootStoreProvider';
import { specialRouteHrefs } from '../../../utils/const';
import LinkButton from '../../LinkButton';
import CartIcon from './CartIcon';

const CartIconButton = () => {
  const { cart, loading } = useCartStore();
  return (
    <LinkButton
      aria-label="cart"
      href={specialRouteHrefs.cart({ id: cart?.id })}
      disableAsPath
      buttonProps={{
        fontSize: 'md',
        variant: 'ghosted',
        minWidth: '48px',
        'aria-label': 'cart',
        disabled: loading || !cart?.id,
      }}
    >
      <CartIcon />
    </LinkButton>
  );
};

export default observer(CartIconButton);
