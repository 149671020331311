import { NextApiRequest, NextApiResponse } from 'next';

import { BASE_URL, apiError } from '../utils';

const shopifySearchSuggest = async (req: NextApiRequest, res: NextApiResponse) => {
  try {
    const { q } = req?.query;

    const response = await fetch(
      `https://${process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN}/search/suggest.json?q=${q}`,
    );

    const result = await response?.json();
    res.status(200);
    res.json(result?.resources?.results?.queries ?? null);
  } catch (error) {
    console.error(error);

    apiError({
      errorInText: 'Search Suggestion',
      error,
      res,
    });
  }
};

export enum CollectionHandles {
  newOrchidArrivals = 'new-arrivals',
}

export const searchSuggest = async (query: string) => {
  try {
    const response = await fetch(`${BASE_URL}/api/shopify/search-suggest?q=${query}`);

    const resJson = await response?.json();
    return resJson;
  } catch (e: any) {
    console.error('searchSuggest failed', e);
    if (e) return e;
  }
};

export default shopifySearchSuggest;
